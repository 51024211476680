import React from 'react'
import Slider from '../components/control.js'
import Cookies from 'js-cookie'

const backend_url = process.env.BACKEND_URL

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        license: Cookies.get('toonify-license'),
        value: '',
        preview: 'https://via.placeholder.com/150',
        toonified_image: null,
        aligned_image: 'https://via.placeholder.com/150',
        uses: undefined,
        valid: undefined,
        processing: false,
        selectedFile: null,
        show_aligned: true,
        error: false,
        error_msg: null,
        controls: {
          age: 0,
          smile: 0,
          angry: 0,
          masculine: 0,
          glasses: 0,
          big_eyes: 0,
        }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeLicense = this.handleChangeLicense.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.checkLicense = this.checkLicense.bind(this);
    this.togglePair = this.togglePair.bind(this);
  }

  togglePair() {
    this.setState((state, props) => ({show_aligned: !state.show_aligned}))
  }

  handleChange(event, name) {
    let newControls = { ...this.state.controls, [name]: event.target.value}
    this.setState({controls: newControls});
  }
  
  checkLicense() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({"license_key":this.state.license,"product_id":"HSlFf"});

    var requestOptions = {
      mode: 'cors',
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(backend_url + "lic-check", requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({uses: result.remaining_uses, valid: result.valid})
      })
      .catch(error => console.log('error', true));
  }

  handleChangeLicense(event) {    this.setState({license: event.target.value});  }

  handleSubmit(event) {
    this.setState({error:false, processing:true})

    let formData = new FormData();
    formData.append("image", this.state.selectedFile);
    formData.append("license_key", this.state.license)
    if (this.props.hasControls) {
      for (const [key, value] of Object.entries(this.state.controls)) {
        formData.append(key, value);
      }
    }

    fetch(backend_url + this.props.model, {
      method: "POST",
      body: formData,
    }).then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json()
      })
      .then(result => 
        {
          if (result.status === "fail") {
          this.setState(
            {
              error: true,
              error_msg: result.message,
              toonified_image: null,
              aligned_image: null,
              processing: false
            })
          } else {
            this.setState(
              {
                toonified_image: "data:image/jpeg;base64," + result.toonified_image, 
                aligned_image: "data:image/jpeg;base64," + result.aligned_image, 
                processing: false,
                uses: result.remaining_credits,
              })
          }
        })
      .catch(error => this.setState({error: true, processing:false, error_msg: "error submitting image"}));
    event.preventDefault();
  }
  handleImage(event) {
    const theFile = event.target.files[0]
    const image = URL.createObjectURL(theFile)
    this.setState({selectedFile: theFile, preview: image}, () => console.log(this.state.selectedFile));
  }

  render() {

    let licenseInput
    let licenseAlert
    if (this.props.creditsPerUse >= 0) {
      licenseInput = (
        <div className="form-group">
            <label htmlFor="license_key">License key&nbsp;<a href="#license-purchase">(?)</a></label>
            <div className="input-group mb-3">
              <input 
                className="form-control" 
                id="license_key" 
                name="license_key" 
                placeholder="Enter license key" 
                value={this.state.license} 
                onChange={this.handleChangeLicense} />
              <div className="input-group-append">
                <button id="lic" 
                  className="btn btn-outline-secondary" 
                  type="button" 
                  disabled={this.state.license===""}
                  onClick={this.checkLicense}>Check</button>
              </div>
            </div>
        </div>
      )

      if (this.state.valid === false) {
        licenseAlert = (<div className="alert alert-danger mt-2 py-1" role="alert">
                        <small>Sorry that doesn't look like a valid license key.</small>
                      </div>)
        Cookies.set('toonify-license', '')
      } else if (this.state.valid === true) {

        Cookies.set('toonify-license', this.state.license)
        licenseAlert = (<div className="alert alert-info mt-2 py-1" role="alert">
                  <small>{this.state.uses} HD uses remaining (unlimited SD)</small>
                </div>)
      }
    }

    let errorMessage
    if (this.state.error) {
      errorMessage = <div className="alert alert-danger" role="alert">
        {"Sorry there was a problem: " + this.state.error_msg}
        </div>
    }

    let controls
    if (this.props.hasControls) {
      controls = <div className="card mb-3">
        <div className="card-header">Controls</div>
        <div className="card-body">
          <div className="form-row">
            <Slider label="Age" value={this.state.controls.age} handleChange={(e) => this.handleChange(e, "age")} />
            <Slider label="Smile" value={this.state.controls.smile} handleChange={(e) => this.handleChange(e, "smile")} />
          </div>
          <div className="form-row">
            <Slider label="Angry" value={this.state.controls.angry} handleChange={(e) => this.handleChange(e, "angry")} />
            <Slider label="Masculine" value={this.state.controls.masculine} handleChange={(e) => this.handleChange(e, "masculine")} />
          </div>
          <div className="form-row">
            <Slider label="Glasses" value={this.state.controls.glasses} handleChange={(e) => this.handleChange(e, "glasses")} />
            <Slider label="Big eyes" value={this.state.controls.big_eyes} handleChange={(e) => this.handleChange(e, "big_eyes")} />
          </div>
        </div>
      </div>
    }

    let results
    if (this.state.toonified_image) {
      results = (
        <>
        <div className="row mb-3">
          {this.state.show_aligned && 
            <div className="col-lg mb-2">
              <img className="img-fluid mx-auto d-block" src={this.state.aligned_image}></img>
            </div>}
          <div className="col-lg mb-2">
            <img className="img-fluid mx-auto d-block" src={this.state.toonified_image}></img>
          </div>
        </div>

        <div className="row text-center mb-3">
          <div className="col-md-6 mx-auto">
            <a className="btn btn-primary mx-2" href={this.state.toonified_image} download="ToonifiedImage">Download</a>
            <a className="btn btn-primary mx-2" onClick={this.togglePair.bind(this)}>Toogle original</a>
          </div>
        </div>
        </>)
      }

    let loadingButton = (
      <button className="btn btn-primary mx-2 btn-lg" type="button" disabled>
        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
        Loading...
      </button>
    )

    let toonifyButton = (
      <>
          { this.state.selectedFile ?
            <button id="toonify" type="upload" className="btn btn-primary mx-2 btn-lg" onSubmit={this.handleSubmit.bind(this)}>Toonify!</button> :
            <button id="toonify" type="upload" className="btn btn-primary mx-2 btn-lg" disabled>Please upload an image above</button> }
            <small className="text-muted">This will use: {Math.max(this.props.creditsPerUse, 0)} credits</small>
      </>
    )
    let submitButton = (
      <div className="form-row">
        <div className="form-group col-md text-center">
          {this.state.processing ? loadingButton : toonifyButton}
        </div>
      </div>
    )

    return (
      <div className="container">
        <form encType="multipart/form-data" id="my-form" onSubmit={this.handleSubmit.bind(this)} >
          <h1 className="mb-3">{this.props.modelName}</h1>
          <div className="form-row">
            <div className="form-group col-md mr-3">
              <div className="form-group custom-file mb-3">
                <input className="custom-file-input" id="image" name="image" type="file" onChange={this.handleImage}/>
                <label className="custom-file-label" htmlFor="image">Upload an image!</label>
              </div>

              {licenseInput}

              {licenseAlert}

            </div>
              <div className="form-group col-md-3">
                <img src={this.state.preview} className="img-thumbnail rounded"></img>
              </div>    
            </div>

            {controls}
            {submitButton}
        </form> 
      
        {results}
        
        {errorMessage}
      </div>
    );
  }
}

export default NameForm
