import React from "react"

const Feature = (props) => {

    let orderStyle = props.rtl ? ["2", "1"] : ["1", "2"]
    
    return (
        <div className="row mb-4 py-3">
            <div className={"description col-md-" + (12 - props.contentWidth) + " pt-5 px-5 order-md-" + orderStyle[0]}>
                <h2>{props.title}</h2>
                <p className="lead">{props.description}</p>
                <p className="text-right text-muted"><em>{props.detail}</em></p>
            </div>
            <div className={"col-md-" + props.contentWidth + " order-md-" + orderStyle[1]}>
                {props.children}
            </div>
        </div>
  )
}

export default Feature
