import React from "react"
import Spritesheet from 'react-responsive-spritesheet';

const sprite = "../images/sprite.jpg"

class SliderImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {posX: 0, posY: 0}
        this.handleChangeX = this.handleChangeX.bind(this)
        this.handleChangeY = this.handleChangeY.bind(this)
        this.updatePos = this.updatePos.bind(this)
        
    }


    handleChangeX(event) {    
        this.updatePos(event.target.value, this.state.posY)
    }

    handleChangeY(event) {    
        this.updatePos(this.state.posX, event.target.value)
    }

    componentDidMount() {
        this.updatePos(0, 0)
    }

    // componentWillUnmount() {
    //     if (typeof window !== 'undefined') {
    //         window.removeEventListener('scroll', this.handleScroll);
    //     }
    // }

    updatePos = (x, y) => {
        this.setState({
            posX: x,
            posY: y,
        });
        let pos = (parseInt(y) + 2)*5 + (parseInt(x)+2)
        if (typeof this.spritesheetInstance !== 'undefined') {
            this.spritesheetInstance.goToAndPause(String(pos))
        }
    }

    // handleScroll = ev => {
        // let val =5 *(window.scrollY/window.innerHeight) % 5
        // this.updatePos(val, 0)
    // };


    render() {

        if (typeof window !== 'undefined') {
            return (
                <div className="slider">
                        <Spritesheet
                                className="img-fluid mb-3"
                                image={sprite}
                                widthFrame={256}
                                heightFrame={256}
                                steps={25}
                                fps={12}
                                autoplay={false}
                                isResponsive={true}
                                startAt={13}
                                getInstance={spritesheet => {
                                    this.spritesheetInstance = spritesheet;
                                }}
                            />
                        
                        <div className="form-row my-2 mx-2">
                            <div className="col">
                                <label htmlFor="range1">{this.props.xLabel}</label>
                            </div>
                            <div className="col-8">
                                <input type="range" 
                                    className="custom-range" 
                                    min="-2" max="2" step="1" 
                                    id="range1" 
                                    value={this.state.posX}  
                                    onChange={this.handleChangeX.bind(this)}/>
                            </div>
                        </div>

                        <div className="form-row my-2 mx-2">
                            <div className="col">
                                <label htmlFor="range2">{this.props.yLabel}</label>
                            </div>
                            <div className="col-8">
                                <input type="range" 
                                    className="custom-range" 
                                    min="-2" max="2" step="1" 
                                    id="range2" 
                                    value={this.state.posY}  
                                    onChange={this.handleChangeY.bind(this)}/>
                            </div>
                        </div>
                </div>
            )
        }
        return null
    }
}

export default SliderImage
