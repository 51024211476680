import React from "react"
import {Helmet} from "react-helmet";

import Layout from "../components/layout"
import SEO from "../components/seo"
import NameForm from "../components/form"
import ModelCard from "../components/model-card"
import Feature from "../components/feature"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import SliderImage from '../components/slider-image'
 
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./overflow.css"

const models = [
  {
    id: "toonmojihd",
    name: "Toonmoji HD",
    description: "Hi-res life-like emoji face.",
    creditsPerUse: 1,
    hasControls: true,
    label: "NEW! HD",
    image: "/images/toonmojihd.jpg",
  },
  {
    id: "caricaturehd",
    name: "Caricature HD",
    description: "Hi-res caricature portrait.",
    creditsPerUse: 1,
    hasControls: true,
    label: "NEW! HD",
    image: "/images/caricaturehd.jpg",
  },
  {
    id: "comichd",
    name: "Comic HD",
    description: "Become a hi-res comic book hero.",
    creditsPerUse: 1,
    hasControls: true,
    label: "NEW! HD",
    image: "/images/comichd.jpg",
  },
  {
    id: "toonifyhd",
    name: "Toonify HD",
    description: "Better quality, higher resolution.",
    creditsPerUse: 1,
    hasControls: true,
    label: "HD",
    image: "/images/hires3.jpg",
  },
  {
    id: "comic",
    name: "Comic",
    description: "Become a comic book superhero.",
    creditsPerUse: 0,
    hasControls: false,
    label: "SD",
    image: "/images/gosling_comic.jpg",
  },
  {
    id: "toonify",
    name: "Toonify Classic",
    description: "The original Toonify.",
    creditsPerUse: -1,
    hasControls: false,
    label: "Free",
    image: "/images/winslet_toonify_classic.jpg",

  },
  {
    id: "caricature",
    name: "Caricature",
    description: "A caricature portrait of yourself.",
    creditsPerUse: 0,
    hasControls: false,
    label: "SD",
    image: "/images/dicaprio_caricature.jpg",
  },
  {
    id: "emojify",
    name: "Emojify",
    description: "Make yourself an emoji.",
    creditsPerUse: 0,
    hasControls: false,
    label: "SD",
    image: "/images/will_smith_emoji.jpg",
  },

  {
    id: "halloweenify",
    name: "Halloweenify",
    description: "Make yourself scary.",
    creditsPerUse: 0,
    hasControls: false,
    label: "SD",
    image: "/images/picard_halloween.jpg",
  },
]

 
const images = [
  {
    original: "images/examples/display/gadot.jpg",
    fullscreen: "images/examples/full/gadot.jpg",
    thumbnail: "images/examples/thumbs/gadot.jpg",
},
{
    original: "images/examples/display/arnold.jpg",
    fullscreen: "images/examples/full/arnold.jpg",
    thumbnail: "images/examples/thumbs/arnold.jpg",
},
{
    original: "images/examples/display/obama_emoji.jpg",
    fullscreen: "images/examples/full/obama_emoji.jpg",
    thumbnail: "images/examples/thumbs/obama_emoji.jpg",
},
{
    original: "images/examples/display/allen.jpg",
    fullscreen: "images/examples/full/allen.jpg",
    thumbnail: "images/examples/thumbs/allen.jpg",
},
{
    original: "images/examples/display/boseman.jpg",
    fullscreen: "images/examples/full/boseman.jpg",
    thumbnail: "images/examples/thumbs/boseman.jpg",
},
{
    original: "images/examples/display/caprio.jpg",
    fullscreen: "images/examples/full/caprio.jpg",
    thumbnail: "images/examples/thumbs/caprio.jpg",
},
{
    original: "images/examples/display/deniro_emoji.jpg",
    fullscreen: "images/examples/full/deniro_emoji.jpg",
    thumbnail: "images/examples/thumbs/deniro_emoji.jpg",
},
{
    original: "images/examples/display/depp_emoji.jpg",
    fullscreen: "images/examples/full/depp_emoji.jpg",
    thumbnail: "images/examples/thumbs/depp_emoji.jpg",
},
{
    original: "images/examples/display/gosling.jpg",
    fullscreen: "images/examples/full/gosling.jpg",
    thumbnail: "images/examples/thumbs/gosling.jpg",
},
{
    original: "images/examples/display/hd_saldana.jpg",
    fullscreen: "images/examples/full/hd_saldana.jpg",
    thumbnail: "images/examples/thumbs/hd_saldana.jpg",
},
{
    original: "images/examples/display/hd_stone.jpg",
    fullscreen: "images/examples/full/hd_stone.jpg",
    thumbnail: "images/examples/thumbs/hd_stone.jpg",
},
{
    original: "images/examples/display/reeves_caricature.jpg",
    fullscreen: "images/examples/full/reeves_caricature.jpg",
    thumbnail: "images/examples/thumbs/reeves_caricature.jpg",
},
{
    original: "images/examples/display/saldana.jpg",
    fullscreen: "images/examples/full/saldana.jpg",
    thumbnail: "images/examples/thumbs/saldana.jpg",
},
{
    original: "images/examples/display/sanada.jpg",
    fullscreen: "images/examples/full/sanada.jpg",
    thumbnail: "images/examples/thumbs/sanada.jpg",
},
{
    original: "images/examples/display/smith.jpg",
    fullscreen: "images/examples/full/smith.jpg",
    thumbnail: "images/examples/thumbs/smith.jpg",
},
{
    original: "images/examples/display/stone.jpg",
    fullscreen: "images/examples/full/stone.jpg",
    thumbnail: "images/examples/thumbs/stone.jpg",
},
{
    original: "images/examples/display/thompson.jpg",
    fullscreen: "images/examples/full/thompson.jpg",
    thumbnail: "images/examples/thumbs/thompson.jpg",
},
{
    original: "images/examples/display/watanabe.jpg",
    fullscreen: "images/examples/full/watanabe.jpg",
    thumbnail: "images/examples/thumbs/watanabe.jpg",
},
{
    original: "images/examples/display/winslet.jpg",
    fullscreen: "images/examples/full/winslet.jpg",
    thumbnail: "images/examples/thumbs/winslet.jpg",
},

]

class IndexPage extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      model: "toonmojihd"
    }
  }


  render(){

    let selectedModel = models.filter((el) => el.id === this.state.model)[0]

    return (

    <Layout>
      <SEO title="Home" />
      
      <div className="jumbotron jumbotron-fluid" style={{backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/images/out.jpg)", backgroundRepeat:"repeat", backgroundSize:"30em"}}>
        <div className="container mb-5">
          <div className="row text-center mb-5">
            <div className="col-md-6 mx-auto">
              <h1 className="display-4 text-white">Toonify!</h1>
              <p className="lead text-white">Toonify is a set of AI powered tools for face transformation.</p>
              <p className="lead text-white">Upload a photo and see what you'd look like in your own CGI movie.</p>
            </div>
          </div>

          <div className="row text-center">
          <div className="col-md-6 mx-auto">
            <AnchorLink className="btn btn-primary mb-2 mx-2" href="#features">See what it can do</AnchorLink >
            <AnchorLink  className="btn btn-primary mb-2 mx-2" href="#toonify-form">Start Toonifying!</AnchorLink >
            </div>
          </div>
        </div>
      </div>
      

      <div className="container mb-5" id="features">
        <Feature
          title="Upload a photo and see what you'd look like in an animated movie"
          description="Our neural networks will reimagine your face, see what they come up with!"
          rtl={false}
          contentWidth={8}
          >
            <ImageGallery items={images} autoPlay={true} showNav={false}/>
          </Feature>

        <Feature
          title="Edit the image to get the look you want."
          description="Use the slider controls to edit features like age, smile and anger."
          detail="Only available for HD models."
          rtl={true}
          contentWidth={5}
          >
            <SliderImage xLabel="Smile:" yLabel="Age:" />
          </Feature>

        <Feature
          title="Try out all our different face transformation models"
          description="As well as Toonify, see what you'd be like as a caricature, emoji, or horror character."
          rtl={false}
          contentWidth={5}
          >
            <img src="images/models.jpg" className="img-fluid"/>
          </Feature>

      

      </div>

      <div className="container mb-5" id="toonify-form">
        <div className="row">
          <div className="col-md-8">
            <div className="has-overflow">
              <div className="p-4 mb-3 bg-light rounded border">
                <p className="lead">Select your transformation:</p>

                  <div className="row flex-nowrap" style={{overflow: "auto"}}>

                    {models.map((model) => 
                      (
                        <div className="mx-3" style={{minWidth:"10rem", width:"10rem"}}>
                          <a onClick={()=>this.setState({model: model.id})}>
                            <ModelCard 
                              modelName={model.name}
                              modelDescription={model.description}
                              selected={this.state.model === model.id}
                              label={model.label}
                              image={model.image}
                              />
                          </a>
                        </div>
                      )
                    )}
                    
                  </div>
              </div>
            </div>
            <div className="p-4 mb-3 rounded border">
              <NameForm 
                model={this.state.model} 
                modelName={selectedModel.name}
                creditsPerUse={selectedModel.creditsPerUse}
                hasControls={selectedModel.hasControls}
                disabled={selectedModel.label === "Coming soon"}/>
            </div>
          </div>
          <aside className="col-md-4">
            <div className="p-4 mb-3 bg-light rounded">
              <h4 className="mb-3">To get the best results:</h4>
              <ul>
                <li className="mb-3">Use a clear and sharp image where your face is the main subject</li>
                <li className="mb-3">Make sure you're facing toward the camera</li>
                <li className="mb-3">Avoid multiple faces (we'll only process the first we find)</li>
                <li className="mb-3">Avoid things that obscure your face</li>
                <li className="mb-3">Try the different models as they all perform differently</li>
              </ul>
            </div>
          </aside>
        </div>
      </div>


    <div className="container" id="license-purchase">
      <h2>Get Toonifying!</h2>
      <p className="lead">The original Toonify Classic model is free to use as much as you like!</p> 
      <p className="lead mb-5">All the other face transformations require a license to be purchased. 
      Any license permits unlimited usage of the SD models 
      whereas the HD model is limited to a certain number of images depending on the license option purchased.</p>

      <div className="card-deck mb-3 text-center">

        <div className="card mb-4 box-shadow">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Free</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">Toonify Classic</h1>
            <p>The original Toonify model is free for everyone to enjoy!</p>
            <AnchorLink href="#toonify-form" className="btn btn-lg btn-block btn-primary">Try it!</AnchorLink>
          </div>
        </div>

        <div className="card mb-4 box-shadow">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">10 Images</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">£3</h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>Unlimited SD models</li>
              <li>10 images HD models</li>
            </ul>
            <a href="https://gum.co/HSlFf?variant=10%20Images" className="btn btn-lg btn-block btn-primary">Buy</a>
          </div>
        </div>
        
        <div className="card mb-4 box-shadow">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">30 Images</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">£5</h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>Unlimited SD models</li>
              <li>20 images HD models</li>
            </ul>
            <a href="https://gum.co/HSlFf?variant=30%20Images" className="btn btn-lg btn-block btn-primary">Buy</a>
          </div>
        </div>
        
        <div className="card mb-4 box-shadow">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">100 Images</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">£10</h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>Unlimited SD models</li>
              <li>100 images HD models</li>
            </ul>
            <a href="https://gum.co/HSlFf?variant=100%20Images" className="btn btn-lg btn-block btn-primary">Buy</a>
          </div>
        </div>

        <div className="card mb-4 box-shadow">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">1000 Images</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">£25</h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>Unlimited SD models</li>
              <li>1000 images HD models</li>
            </ul>
            <a href="https://gum.co/HSlFf?variant=1000%20Images" className="btn btn-lg btn-block btn-primary">Buy</a>
          </div>
        </div>
      </div>
      </div>
    </Layout>
  )
}
}
export default IndexPage
