import React from 'react'

class Slider extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
        return (
            <div className="form-group col-md">
              <label htmlFor="customRange3">{this.props.label} {this.props.value}</label>
              <input type="range" className="custom-range" min="-5" max="5" step="1" id="customRange3" value={this.props.value} onChange={this.props.handleChange}/>
            </div>
        )
    }

}

export default Slider