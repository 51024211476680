import React from "react"
import "./model-card.css"

const ModelCard = (props) => {

    let style = {}
    if (props.selected) {
        style = {
            outline: "none",
            borderColor: "#9ecaed",
            boxShadow: "0 0 10px #9ecaed"
        }
    }
  
  return (
        <div className="card mb-6" style={style}>
            <span className="notify-badge">{props.label}</span>
            <img className="card-img-top" src={props.image}/>
            <div className="card-body">
                <h5 className="card-title">{props.modelName}</h5>
                <p className="card-text text-muted">{props.modelDescription}</p>
            </div>
        </div>
  )
}

export default ModelCard
